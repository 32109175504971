import React from 'react';
import { XeroActionType } from './types';
import { formatCurrency } from '../self-publishing/PublicationSettings/CurrencyUtils';
import { t } from '../../../../assets/i18n/i18n';
import Moment from 'react-moment';
import mixam from '../../../boot/mixam.js';
var OrderInvoice = function (_a) {
    var invoices = _a.invoices, invoiceUrls = _a.invoiceUrls, accountingServiceType = _a.accountingServiceType;
    return (React.createElement("div", null,
        React.createElement("h3", null, t('orders.payment.invoices')),
        React.createElement("table", { className: "table invoices" },
            React.createElement("colgroup", null,
                React.createElement("col", { style: { width: '20%' } }),
                React.createElement("col", { style: { width: '20%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } })),
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, t('orders.payment.invoice')),
                    React.createElement("th", null, t('orders.payment.link')),
                    React.createElement("th", null, t('orders.payment.transactionDate')),
                    React.createElement("th", null, t('orders.payment.transactionType')),
                    React.createElement("th", null, t('orders.payment.transactionStatus')),
                    React.createElement("th", null, t('orders.payment.transactionAmount')))),
            React.createElement("tbody", null, invoices.map(function (invoice, index) {
                return (React.createElement("tr", { key: index, className: "type-".concat(XeroActionType[invoice.type]) },
                    React.createElement("td", null, invoice.invoice),
                    React.createElement("td", null, invoice.status !== 'DRAFT' &&
                        accountingServiceType === 1 && (React.createElement("a", { href: invoiceUrls[invoice.invoice], target: "_blank", rel: "noopener noreferrer" },
                        t('orders.payment.invoicePdf'),
                        ' ',
                        React.createElement("i", { className: "fa fa-external-link", "aria-hidden": "true" })))),
                    React.createElement("td", null,
                        React.createElement("span", null,
                            React.createElement(Moment, { format: "lll" }, invoice.date))),
                    React.createElement("td", null, invoice.type === XeroActionType.INVOICE
                        ? t('orders.payment.invoice')
                        : t('orders.payment.creditNote')),
                    React.createElement("td", null, invoice.status),
                    React.createElement("td", { className: "text-right" },
                        invoice.type === XeroActionType.CREDITNOTE ? '-' : '',
                        formatCurrency(invoice.total, mixam.shop.languageTag))));
            })))));
};
export default OrderInvoice;
