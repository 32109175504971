import React, { useEffect, useState } from 'react';
import { Locales } from "@mixam-platform/mixam-types";
import axios from 'axios';
import { I18nextProvider } from 'react-i18next';
import i18nShop from "../../../../assets/i18n/i18n";
import { MonduPaymentType } from './types';
export var MonduPaymentManager = function (_a) {
    var orderId = _a.orderId, amount = _a.amount, locale = _a.locale;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(null), tradeAccount = _c[0], setTradeAccount = _c[1];
    var localeLanguageDef = useState(Locales[locale])[0];
    useEffect(function () {
        axios
            .get("/api/mondu/trade-account/orders/".concat(orderId))
            .then(function (response) { return setTradeAccount(response.data); })
            .catch(function (error) { return console.error("Error fetching trade account details:", error); })
            .finally(function () { return setLoading(false); });
    }, []);
    var handleCheckout = function (paymentType) {
        var payload = {
            orderId: orderId.toString(),
            monduPaymentType: paymentType
        };
        axios.post('/api/mondu/checkout', payload)
            .then(function (response) {
            var _a;
            window.location.href = (_a = response.data.redirectUrl) !== null && _a !== void 0 ? _a : console.error("No redirect URL returned from checkout.");
        })
            .catch(function (error) { return console.error("Error during checkout:", error); });
    };
    var handleTradeAccountPayment = function () {
        if (tradeAccount === null || tradeAccount === void 0 ? void 0 : tradeAccount.hasTradeAccount) {
            handleCheckout(MonduPaymentType.BILLING_STATEMENT);
        }
        else {
            var payload = { orderId: orderId.toString() };
            axios.post('/api/mondu/trade-account/create', payload)
                .then(function (response) {
                var _a;
                window.location.href = (_a = response.data.redirectUrl) !== null && _a !== void 0 ? _a : console.error("No redirect URL returned from trade account creation.");
            })
                .catch(function (error) { return console.error("Error during trade account creation:", error); });
        }
    };
    var handleInvoicePayment = function () { return handleCheckout(MonduPaymentType.INVOICE); };
    var handleInstallmentPayment = function () { return handleCheckout(MonduPaymentType.INSTALLMENT_BY_INVOICE); };
    if (loading) {
        return React.createElement("div", { className: "text-center", style: { padding: '20px' } },
            React.createElement("span", null, "Loading..."));
    }
    var monduButtonStyle = {
        backgroundColor: "#8B4AFF",
        borderColor: "#8B4AFF",
        color: "white"
    };
    var convertCentsToUnits = function (cents) { return cents / 100; };
    var formatCurrency = function (value) { return value.toLocaleString(locale, {
        style: 'currency',
        currency: localeLanguageDef.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }); };
    var spentPercent = 0;
    var allowedPercent = 0;
    if ((tradeAccount === null || tradeAccount === void 0 ? void 0 : tradeAccount.hasTradeAccount) && tradeAccount.purchasingLimit) {
        var _d = tradeAccount.purchasingLimit, purchasingLimitCents = _d.purchasingLimitCents, balanceCents = _d.balanceCents, maxPurchaseValueCents = _d.maxPurchaseValueCents;
        spentPercent = purchasingLimitCents > 0 ? (balanceCents / purchasingLimitCents) * 100 : 0;
        allowedPercent = purchasingLimitCents > 0 ? (maxPurchaseValueCents / purchasingLimitCents) * 100 : 0;
    }
    return (React.createElement(I18nextProvider, { i18n: i18nShop },
        React.createElement("div", null,
            React.createElement("h4", null, i18nShop.t('mondu.amountDue', { amount: formatCurrency(amount) })),
            React.createElement("p", null, i18nShop.t('mondu.description')),
            React.createElement("div", { className: "panel panel-default" },
                React.createElement("div", { className: "panel-heading" },
                    React.createElement("h3", { className: "panel-title" }, i18nShop.t('mondu.tradeAccount.title'))),
                React.createElement("div", { className: "panel-body" },
                    (tradeAccount === null || tradeAccount === void 0 ? void 0 : tradeAccount.hasTradeAccount) ? (React.createElement(React.Fragment, null,
                        React.createElement("p", null, i18nShop.t('mondu.tradeAccount.readyDescription')),
                        tradeAccount.purchasingLimit && (React.createElement("div", null,
                            React.createElement("p", null,
                                React.createElement("strong", null, i18nShop.t('mondu.tradeAccount.creditLimit', {
                                    amount: formatCurrency(convertCentsToUnits(tradeAccount.purchasingLimit.purchasingLimitCents))
                                }))),
                            React.createElement("p", null,
                                React.createElement("strong", null, i18nShop.t('mondu.tradeAccount.spent', {
                                    amount: formatCurrency(convertCentsToUnits(tradeAccount.purchasingLimit.balanceCents))
                                }))),
                            React.createElement("p", null,
                                React.createElement("strong", null, i18nShop.t('mondu.tradeAccount.maxPurchase', {
                                    amount: formatCurrency(convertCentsToUnits(tradeAccount.purchasingLimit.maxPurchaseValueCents))
                                }))),
                            React.createElement("div", { className: "progress" },
                                React.createElement("div", { className: "progress-bar progress-bar-danger", style: { width: "".concat(spentPercent, "%") } }, i18nShop.t('mondu.tradeAccount.spentPercent', { percent: spentPercent.toFixed(0) })),
                                React.createElement("div", { className: "progress-bar progress-bar-success", style: { width: "".concat(allowedPercent, "%") } }, i18nShop.t('mondu.tradeAccount.availablePercent', { percent: allowedPercent.toFixed(0) }))))))) : (React.createElement("p", null, i18nShop.t('mondu.tradeAccount.applyDescription'))),
                    React.createElement("button", { className: "btn", style: monduButtonStyle, onClick: handleTradeAccountPayment }, i18nShop.t((tradeAccount === null || tradeAccount === void 0 ? void 0 : tradeAccount.hasTradeAccount) ? 'mondu.tradeAccount.payButton' : 'mondu.tradeAccount.applyButton')))),
            React.createElement("div", { className: "panel panel-default" },
                React.createElement("div", { className: "panel-heading" },
                    React.createElement("h3", { className: "panel-title" }, i18nShop.t('mondu.invoicePayment.title'))),
                React.createElement("div", { className: "panel-body" },
                    React.createElement("p", null, i18nShop.t('mondu.invoicePayment.description')),
                    React.createElement("ul", null,
                        React.createElement("li", null, i18nShop.t('mondu.invoicePayment.bullet1')),
                        React.createElement("li", null, i18nShop.t('mondu.invoicePayment.bullet2')),
                        React.createElement("li", null, i18nShop.t('mondu.invoicePayment.bullet3'))),
                    React.createElement("button", { className: "btn", style: monduButtonStyle, onClick: handleInvoicePayment }, i18nShop.t('mondu.invoicePayment.button')))),
            React.createElement("div", { className: "panel panel-default" },
                React.createElement("div", { className: "panel-heading" },
                    React.createElement("h3", { className: "panel-title" }, i18nShop.t('mondu.installmentPayment.title'))),
                React.createElement("div", { className: "panel-body" },
                    React.createElement("p", null, i18nShop.t('mondu.installmentPayment.description')),
                    React.createElement("ul", null,
                        React.createElement("li", null, i18nShop.t('mondu.installmentPayment.bullet1')),
                        React.createElement("li", null, i18nShop.t('mondu.installmentPayment.bullet2')),
                        React.createElement("li", null, i18nShop.t('mondu.installmentPayment.bullet3'))),
                    React.createElement("button", { className: "btn", style: monduButtonStyle, onClick: handleInstallmentPayment }, i18nShop.t('mondu.installmentPayment.button')))),
            React.createElement("div", { className: "text-center", style: { marginTop: '15px' } },
                React.createElement("a", { href: "https://www.mondu.ai/en-gb/gdpr-notification-for-buyers-uk/", target: "_blank", rel: "noopener noreferrer" }, i18nShop.t('mondu.gdprLink'))))));
};
