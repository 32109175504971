var _a;
export var XeroActionType;
(function (XeroActionType) {
    XeroActionType[XeroActionType["INVALID"] = 0] = "INVALID";
    XeroActionType[XeroActionType["INVOICE"] = 1] = "INVOICE";
    XeroActionType[XeroActionType["CREDITNOTE"] = 2] = "CREDITNOTE";
})(XeroActionType || (XeroActionType = {}));
export var TransactionType;
(function (TransactionType) {
    TransactionType["PAYMENT"] = "PAYMENT";
    TransactionType["DEFERRED"] = "DEFERRED";
    TransactionType["AUTHENTICATE"] = "AUTHENTICATE";
    TransactionType["TOKEN"] = "TOKEN";
    TransactionType["REMOVETOKEN"] = "REMOVETOKEN";
    TransactionType["ABORT"] = "ABORT";
    TransactionType["REFUND"] = "REFUND";
    TransactionType["CANCEL"] = "CANCEL";
    TransactionType["VOID"] = "VOID";
    TransactionType["RELEASE"] = "RELEASE";
    TransactionType["COMPLETE"] = "COMPLETE";
    TransactionType["REPEAT"] = "REPEAT";
    TransactionType["REPEATDEFERRED"] = "REPEATDEFERRED";
    TransactionType["MANUAL"] = "MANUAL";
    TransactionType["DIRECTREFUND"] = "DIRECTREFUND";
    TransactionType["AUTHORISE"] = "AUTHORISE";
})(TransactionType || (TransactionType = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod[PaymentMethod["COUPON"] = -1] = "COUPON";
    PaymentMethod[PaymentMethod["ACCOUNT"] = 0] = "ACCOUNT";
    PaymentMethod[PaymentMethod["CARD_SAGE"] = 1] = "CARD_SAGE";
    PaymentMethod[PaymentMethod["BANK"] = 2] = "BANK";
    PaymentMethod[PaymentMethod["PAYPAL"] = 3] = "PAYPAL";
    PaymentMethod[PaymentMethod["CARD_DIBS"] = 4] = "CARD_DIBS";
    PaymentMethod[PaymentMethod["CARD_STRIPE"] = 5] = "CARD_STRIPE";
    PaymentMethod[PaymentMethod["PAY_BY_PHONE"] = 6] = "PAY_BY_PHONE";
    PaymentMethod[PaymentMethod["AMAZON_PAY"] = 7] = "AMAZON_PAY";
    PaymentMethod[PaymentMethod["DIGITAL_WALLET"] = 8] = "DIGITAL_WALLET";
    PaymentMethod[PaymentMethod["PAYPAL_MANUAL"] = 9] = "PAYPAL_MANUAL";
    PaymentMethod[PaymentMethod["CUSTOMER_CREDIT"] = 10] = "CUSTOMER_CREDIT";
    PaymentMethod[PaymentMethod["AFTERPAY"] = 11] = "AFTERPAY";
    PaymentMethod[PaymentMethod["VOLT"] = 12] = "VOLT";
    PaymentMethod[PaymentMethod["CREDIT_KEY"] = 13] = "CREDIT_KEY";
    PaymentMethod[PaymentMethod["PROFORMA"] = 14] = "PROFORMA";
    PaymentMethod[PaymentMethod["PLAID"] = 15] = "PLAID";
    PaymentMethod[PaymentMethod["MONDU"] = 16] = "MONDU";
})(PaymentMethod || (PaymentMethod = {}));
export var icons = (_a = {},
    _a[PaymentMethod.ACCOUNT] = 'fa-user',
    _a[PaymentMethod.PAY_BY_PHONE] = 'fa-phone',
    _a[PaymentMethod.AMAZON_PAY] = 'mx-amazon',
    _a[PaymentMethod.COUPON] = 'fa-gift',
    _a[PaymentMethod.CARD_SAGE] = 'fa-credit-card-alt',
    _a[PaymentMethod.CUSTOMER_CREDIT] = 'fa-gift',
    _a[PaymentMethod.CARD_DIBS] = 'fa-credit-card-alt',
    _a[PaymentMethod.CARD_STRIPE] = 'fa-credit-card-alt',
    _a[PaymentMethod.VOLT] = 'fa-vimeo',
    _a[PaymentMethod.BANK] = 'fa-university',
    _a[PaymentMethod.PAYPAL] = 'fa-paypal',
    _a[PaymentMethod.AFTERPAY] = 'fa-money',
    _a[PaymentMethod.CREDIT_KEY] = 'fa-file-text-o',
    _a[PaymentMethod.PROFORMA] = 'fa-file-text-o',
    _a[PaymentMethod.PLAID] = 'fa-product-hunt',
    _a[PaymentMethod.MONDU] = 'fa-meetup',
    _a);
