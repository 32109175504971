import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import OrderPayment from "../../react/payment/OrderPayments.tsx";

export default defineComponent(OrderPaymentInitialise);

function OrderPaymentInitialise() {
    this.attributes({
        containerSelector: '[data-type="order-payment-target"]',
    });

    this.initReactComponent = function () {
        const container = this.select('containerSelector').get(0);
        const root = ReactDom.createRoot(container);
        root.render(
            <OrderPayment
                orderId={this.orderId}
            />
        );
    };

    this.after('initialize', function () {
        this.orderId = this.$node.data('order-id');
        this.initReactComponent();
    });
}