import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import { Breakpoints } from '../../table/data-table-net/StyledDataTableNet.tsx';
import axios from "axios";
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";
import {t} from "../../../../assets/i18n/i18n.ts";


export default defineComponent(InvoicesRefundsList);

function InvoicesRefundsList() {

    this.attributes({
        url: "/api/admin/invoice-refunds",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: '',
            type: CELL_TYPES.THUMBNAIL,
            data: "front"
        });

        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });

        cols.push({
            title: "Invoice",
            type: "text",
            data: "invoiceNumber"
        });
        cols.push({
            title: "Date",
            type: CELL_TYPES.TIMEBOX,
            data: "dateCreated"
        });

        cols.push({
            title: "Reason(s)",
            type: "text",
            data: "reason"
        });

        // refundAmount
        cols.push({
            title: "Amount",
            type: "currency",
            data: "refundAmount"
        });
        // refundTaxAmount
        cols.push({
            title: "Tax",
            type: "currency",
            data: "refundTaxAmount"
        });

        // name
        cols.push({
            title: "Name",
            type: "text",
            data: "name",
            hide: Breakpoints.MD
        });

        // email
        cols.push({
            title: "Email",
            type: "text",
            data: "email",
            hide: Breakpoints.XL
        });

        // method
        cols.push({
            title: "Method",
            type: "text",
            data: "methodColumn"
        });

        // comment
        cols.push({
            title: "Comment",
            type: CELL_TYPES.SHOW_MORE_TEXT,
            data: "commentText",
            hide: Breakpoints.XL
        });

        // raised by
        cols.push({
            title: "Raised by",
            type: "text",
            data: "raisedBy",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Action",
            type: CELL_TYPES.INVOICE_REFUND_ACTION
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeInvoiceRefund(x));
    };

    this.normalizeInvoiceRefund = function (invoiceRefundJob) {
        const normalizedEntity = {
            id: invoiceRefundJob.id,
            front: {
                src: invoiceRefundJob.thumbnailUrl,
                href: `/orders/${invoiceRefundJob.orderId}/artwork`,
                target: "_blank"
            },
            href: {
                href: `/orders/${invoiceRefundJob.orderId}/artwork`,
                caption: invoiceRefundJob.caseNumber,
                target: "_blank"
            },
            dateCreated: invoiceRefundJob.dateCreated,
            refundAmount: invoiceRefundJob.invoiceRefundRequest.refundAmount,
            refundTaxAmount: invoiceRefundJob.invoiceRefundRequest.refundTaxAmount,
            refundMethod: invoiceRefundJob.invoiceRefundRequest.method,
            name: invoiceRefundJob.name,
            email: invoiceRefundJob.email,
            status: invoiceRefundJob.status,
            commentText: {
                lines: 3,
                text: invoiceRefundJob.invoiceRefundRequest.comment
            },
            raisedBy: invoiceRefundJob.createdBy,
            invoiceNumber: invoiceRefundJob.originalInvoice.invoiceNumber,
            methodColumn: t(`admin.refund.${invoiceRefundJob.invoiceRefundRequest.method}`),
            lineItems: invoiceRefundJob.invoiceRefundRequest.lineItems,
            originalInvoice: invoiceRefundJob.originalInvoice,
            originalInvoiceLineItems: invoiceRefundJob.originalInvoiceLineItems,
            originalPaymentMethodType: invoiceRefundJob.originaPaymentMethodType,
            notes: invoiceRefundJob.notes
        };

        if(invoiceRefundJob.invoiceRefundRequest.wholeInvoiceReason) {
            normalizedEntity.reason = t(`admin.refund.${invoiceRefundJob.invoiceRefundRequest.wholeInvoiceReason}`);
        } else {
            const uniqueReasons = [...new Map(invoiceRefundJob.invoiceRefundRequest.lineItems.map(refund => [refund.reason, refund])).values()];
            normalizedEntity.reason = uniqueReasons.map(refund => refund.reason).join(", ");
        }

        return normalizedEntity;
    };


    this.getData = function () {
        axios.get(this.attr.url)
            .then((response) => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific invoice
     */
    this.subscribe = function (onMessage) {
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
        };
        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allcreditcontrolchanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const invoice = data.data;
        if (invoice.shopId !== mixam.shop.id) {
            return;
        }

        const index = this.data.map(x => x.id).indexOf(invoice.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data[index] = invoice;
            } else {
                this.data.unshift(invoice);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.splice(index, 1);
        }

        this.paint();
    };
    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(() => this.getData());
    });
}
